//draw svg bezier curves - https://www.codedrome.com/svg-bezier-curves/
import Utils from './utils.js';

class LogoAnimation {
  constructor(linePos, axis, wordEl, debug, isMobile) {
    this.linePos = linePos;
    this.axis = axis;
    this.wordEl = wordEl;
    this.debug = debug;
    this.isMobile = isMobile;

    this.logoEl = this.wordEl.closest('.logo-svg');
    this.widthSvg = 100;
    this.heightSvg = 100;

    this.spacingW = this.widthSvg/4;
    this.spacingH = this.heightSvg/4;

    this.animFirstLoadAnim = true;
    this.isFirstLoadAnimation = true;

    this.rafId = undefined;

    this.offsetLines = [-13, 0, 13];

    if(this.linePos == -1)
      this.offset = this.offsetLines[0]
    else if(this.linePos == 1)
      this.offset = this.offsetLines[2]
    else
      this.offset = this.offsetLines[1]

    this.pointsStartPos = {
      startPoint: {
        x: this.widthSvg/4 * 0,
        y: this.heightSvg/4 * 2 + this.offset
      },
      startCurvePoint: {
        x: this.widthSvg/4 * 1,
        y: this.heightSvg/4 * 2 + this.offset
      },
      endCurvePoint: {
        x: this.widthSvg/4 * 3,
        y: this.heightSvg/4 * 2 + this.offset
      },
      endPoint: {
        x: this.widthSvg/4 * 4,
        y: this.heightSvg/4 * 2 + this.offset
      }
    };

    this.pointsCurrentPos = JSON.parse(JSON.stringify(this.pointsStartPos)); //https://www.freecodecamp.org/news/clone-an-object-in-javascript/ the other two didn't work, why?

    if(this.linePos == 1) {
      //"Institute"
      this.pointsMaxTransformPos = {
        startPoint: {
          y: this._random(this.heightSvg/4 * 3, this.heightSvg/4 * 4), // from 75 to 100
        },
        startCurvePoint: {
          x: this._random(0, this.widthSvg/4 * 3),// from 0 to 75
          y: this._random(this.heightSvg/4 * 1, this.heightSvg/4 * 4)// from 25 to 100
        },
        endCurvePoint: {
          x: this._random(this.widthSvg/4 * 1, this.widthSvg/4 * 4),// from 25 to 100
          y: this._random(this.heightSvg/4 * 3, this.heightSvg/4 * 4)// from 50 to 100
        },
        endPoint: {
          y: this._random(this.heightSvg/4 * 3, this.heightSvg/4 * 4), // from 75 to 100
        }
      };
    }
    else if(this.linePos == -1) {
      //"Interactive"
      this.pointsMaxTransformPos = {
        startPoint: {
          y: this._random(0, this.heightSvg/4 * 1), // from 0 to 25
        },
        startCurvePoint: {
          x: this._random(0, this.widthSvg/4 * 3),// from 0 to 75
          y: this._random(0, this.heightSvg/4 * 3)// from 0 to 75
        },
        endCurvePoint: {
          x: this._random(this.widthSvg/4 * 1, this.widthSvg/4 * 4),// from 25 to 100
          y: this._random(0, this.heightSvg/4 * 3)// from 0 to 75
        },
        endPoint: {
          y: this._random(0, this.heightSvg/4 * 1),//from 0 to 25
        }
      };
    }
    else {
      //"Technologies"
      this.pointsMaxTransformPos = {
        startPoint: {
          y: this._random(this.heightSvg/4 * 1.5, this.heightSvg/4 * 2.5), // from 37.5 to 62.5
        },
        startCurvePoint: {
          x: this._random(this.widthSvg/4 * 1, this.widthSvg/4 * 3),// from 25 to 75
          y: this._random(this.heightSvg/4 * 1, this.heightSvg/4 * 3)// from 25 to 75
        },
        endCurvePoint: {
          x: this._random(this.widthSvg/4 * 1, this.widthSvg/4 * 3),// from 25 to 75
          y: this._random(this.heightSvg/4 * 1, this.heightSvg/4 * 3)// from 25 to 75
        },
        endPoint: {
          y: this._random(this.heightSvg/4 * 1.5, this.heightSvg/4 * 2.5),// from 37.5 to 62.5
        }
      };
    }

    this.wordLetters = this._getLetterPositions();

    this.rafId = undefined;

    this._init();
  }

  /** INIT **/
  _init() {
    this._updatePoints();

    if(this.debug)
      this._gridLines();

    this._drawBezierCurve();

    if(!this.isMobile) {
      let rafId = undefined;
      document.body.addEventListener('mousemove', e => {
        rafId = Utils.debouncedRequestAnimationFrame(rafId, () => {
          if(this.logoEl.classList.contains('first-load') && this.animFirstLoadAnim || Utils.isMobileView())
            this._firstAnimation(e.pageX, e.pageY);

          //After first animation allow mouse animation
          if(!this.isFirstLoadAnimation) {
            this._updatePathBasedOnMousePos(e.pageX, e.pageY);
            this._updateLettersPos();
          }
        });
      });
    } else {
      const xPosRandom = Utils.randomNum(0, window.innerWidth);
      const yPosRandom = Utils.randomNum(0, window.innerHeight);

      this._firstAnimation(xPosRandom, yPosRandom);
    }
  }

  _firstAnimation(x,y) {
     //first animation on first mouse move
    this._updatePathBasedOnMousePos(x, y);
    //start animation for letters with request animation frame
    // - each moment it has to calculate yPos of each letter based in te new positions of bezier curve at the moment of its animation
    this.rafId = this._updateLettersPosReqAnimFrame();

    setTimeout(() => {
      //when animation ends, remove classes and cancel request animation frame for letters animation
      cancelAnimationFrame(this.rafId);

      this.logoEl.classList.remove('first-load');
      this.isFirstLoadAnimation = false;
    }, 100);
    this.animFirstLoadAnim = false;
  }

  _updateLettersPosReqAnimFrame () {
    return window.requestAnimationFrame(() => {
      this._updateLettersPos();
      this.rafId = this._updateLettersPosReqAnimFrame();
    });
  }

  /* LETTER POS FUNCTIONS */
  _getLetterPositions() {
    return [...this.wordEl.children].map(el => {
      const x = this._mapNum(el.getBoundingClientRect().left - this.logoEl.getBoundingClientRect().left, 0, this.logoEl.getBoundingClientRect().width, 0, this.widthSvg);
      const y = this._mapNum(el.getBoundingClientRect().top - this.logoEl.getBoundingClientRect().top, 0, this.logoEl.getBoundingClientRect().height, 0, this.heightSvg);
      const height = this._mapNum(el.getBoundingClientRect().height, 0, this.logoEl.getBoundingClientRect().height, 0, this.heightSvg);

      //debug points
      //this._drawPoints([[x, y]], 'red', true);

      return {
        el: el,
        height: height,
        xPos: x,
        yPos: y,
      }
    });
  }

  _updateLettersPos() {
    const bezierCurveEl = this.logoEl.querySelector('.curve-bezier-'+this.linePos);

    this.wordLetters.forEach(el => {
      const newYPos = this._findYInPath(bezierCurveEl, el.xPos);

      el.el.style.transform = 'translateY('+ (newYPos - el.yPos - el.height/2) +'px)';
    });
  }

  //https://stackoverflow.com/a/47935325
  _findYInPath(path, x) {
    let pathLength = path.getTotalLength();
    let start = 0;
    let end = pathLength;
    let target = (start + end) / 2;

    // Ensure that x is within the range of the path
    x = Math.max(x, path.getPointAtLength(0).x)
    x = Math.min(x, path.getPointAtLength(pathLength).x)

    // Walk along the path using binary search
    // to locate the point with the supplied x value
    while (target >= start && target <= pathLength) {
      let pos = path.getPointAtLength(target);

      // use a threshold instead of strict equality
      // to handle javascript floating point precision
      if (Math.abs(pos.x - x) < 0.001) {
        return pos.y
      } else if (pos.x > x) {
        end = target
      } else {
        start = target
      }
      target = (start + end) / 2
    }
  }


  /* BEZIER CURVE AUX FUNCTIONS */
  _updatePathBasedOnMousePos(pageX, pageY) {
    if(this.axis == 'xy') {
      this.pointsCurrentPos.startPoint.y = this._mapNum((pageX ), 0, window.innerWidth, this.pointsMaxTransformPos.startPoint.y, this.heightSvg - this.pointsMaxTransformPos.startPoint.y, );
      this.logoEl.querySelector('.point-'+this.linePos+'-1').setAttribute('cy', this.pointsCurrentPos.startPoint.y);

      this.pointsCurrentPos.startCurvePoint.x = this._mapNum((pageX ), 0, window.innerWidth, this.pointsMaxTransformPos.startCurvePoint.x, this.pointsMaxTransformPos.startCurvePoint.x, );
      this.logoEl.querySelector('.point-'+this.linePos+'-2').setAttribute('cx', this.pointsCurrentPos.startCurvePoint.x);

      this.pointsCurrentPos.startCurvePoint.y = this._mapNum((pageX ), 0, window.innerWidth, this.pointsMaxTransformPos.startCurvePoint.y, this.heightSvg - this.pointsMaxTransformPos.startCurvePoint.y, );
      this.logoEl.querySelector('.point-'+this.linePos+'-2').setAttribute('cy', this.pointsCurrentPos.startCurvePoint.y);

      this.pointsCurrentPos.endPoint.y = this._mapNum((pageY), 0, window.innerHeight, this.pointsMaxTransformPos.endPoint.y, this.heightSvg - this.pointsMaxTransformPos.endPoint.y);
      this.logoEl.querySelector('.point-'+this.linePos+'-4').setAttribute('cy', this.pointsCurrentPos.endPoint.y);

      this.pointsCurrentPos.endCurvePoint.x = this._mapNum((pageY), 0, window.innerHeight, this.pointsMaxTransformPos.endCurvePoint.x, this.pointsMaxTransformPos.endCurvePoint.x);
      this.logoEl.querySelector('.point-'+this.linePos+'-3').setAttribute('cx', this.pointsCurrentPos.endCurvePoint.x);

      this.pointsCurrentPos.endCurvePoint.y = this._mapNum((pageY), 0, window.innerHeight, this.pointsMaxTransformPos.endCurvePoint.y, this.heightSvg - this.pointsMaxTransformPos.endCurvePoint.y);
      this.logoEl.querySelector('.point-'+this.linePos+'-3').setAttribute('cy', this.pointsCurrentPos.endCurvePoint.y);

    }
    else {
      let mousePos, isFirstHalf;

      if(this.axis == 'x') {
        mousePos = pageX;
        isFirstHalf =  pageX <= window.innerWidth/2;
      }
      else if(this.axis == 'y') {
        mousePos = pageY;
        isFirstHalf =  pageY <= window.innerHeight/2;
      }

      if(isFirstHalf) {
        //meio eixo X mexe o Y do start point
        this.pointsCurrentPos.startPoint.y = this._mapNumInSvg(this.axis, 1, mousePos, this.pointsMaxTransformPos.startPoint.y, this.pointsStartPos.startPoint.y);
        this.logoEl.querySelector('.point-'+this.linePos+'-1').setAttribute('cy', this.pointsCurrentPos.startPoint.y);

        this.pointsCurrentPos.startCurvePoint.x = this._mapNumInSvg(this.axis, 1, mousePos, this.pointsMaxTransformPos.startCurvePoint.x, this.pointsStartPos.startCurvePoint.x);
        this.logoEl.querySelector('.point-'+this.linePos+'-2').setAttribute('cx', this.pointsCurrentPos.startCurvePoint.x);

        this.pointsCurrentPos.startCurvePoint.y = this._mapNumInSvg(this.axis, 1, mousePos, this.pointsMaxTransformPos.startCurvePoint.y, this.pointsStartPos.startCurvePoint.y);
        this.logoEl.querySelector('.point-'+this.linePos+'-2').setAttribute('cy', this.pointsCurrentPos.startCurvePoint.y);

      } else {
        //meio eixo X mexe o Y do end point
        this.pointsCurrentPos.endCurvePoint.x = this._mapNumInSvg(this.axis, 2, mousePos, this.pointsStartPos.endCurvePoint.x, this.pointsMaxTransformPos.endCurvePoint.x);
        this.logoEl.querySelector('.point-'+this.linePos+'-3').setAttribute('cx', this.pointsCurrentPos.endCurvePoint.x);

        this.pointsCurrentPos.endCurvePoint.y = this._mapNumInSvg(this.axis, 2, mousePos, this.pointsStartPos.endCurvePoint.y, this.pointsMaxTransformPos.endCurvePoint.y);
        this.logoEl.querySelector('.point-'+this.linePos+'-3').setAttribute('cy', this.pointsCurrentPos.endCurvePoint.y);

        this.pointsCurrentPos.endPoint.y = this._mapNumInSvg(this.axis, 2, mousePos, this.pointsStartPos.endPoint.y, this.pointsMaxTransformPos.endPoint.y);
        this.logoEl.querySelector('.point-'+this.linePos+'-4').setAttribute('cy', this.pointsCurrentPos.endPoint.y);
      }
    }
    this._updatePoints();

    const newPathString = this._generatePath(this.points, false);
    this.logoEl.querySelector('.curve-bezier-'+this.linePos).setAttribute('d', newPathString);
  }

  _mapNumInSvg(axis, half, position, outMin, outMax) {
    if(axis == 'x') {
      if(half == 1) {
        return this._mapNum(position, 0, window.innerWidth/2, outMin, outMax);
      } else {
        return this._mapNum(position, window.innerWidth/2, window.innerWidth, outMin, outMax);
      }
    } else if(axis == 'y') {
      if(half == 1) {
        return this._mapNum(position, 0, window.innerHeight/2, outMin, outMax);
      } else {
        return this._mapNum(position, window.innerHeight/2, window.innerHeight, outMin, outMax);
      }
    }
    else if(axis == 'xy') {
      if(half == 1) {
        return this._mapNum(position, 0, window.innerHeight/2, outMin, outMax);
      } else {
        return this._mapNum(position, window.innerHeight/2, window.innerHeight, outMin, outMax);
      }
    }

  }

  _updatePoints() {
    this.points = [[this.pointsCurrentPos.startPoint.x, this.pointsCurrentPos.startPoint.y], [this.pointsCurrentPos.startCurvePoint.x, this.pointsCurrentPos.startCurvePoint.y], [this.pointsCurrentPos.endCurvePoint.x, this.pointsCurrentPos.endCurvePoint.y], [this.pointsCurrentPos.endPoint.x, this.pointsCurrentPos.endPoint.y]];
  }


  /* DRAW BEZIER CURVE */
  _drawBezierCurve() {
    this._drawPoints(this.points, this.debug ? '#0000FF': 'transparent');

    const pathString = this._generatePath(this.points, false);
    this._drawBezier(pathString, this.debug ? '#0000FF': 'transparent');
  }

  _drawPoints(points, colour, withoutId) {
    let i = 0;
    for(const point of points) {
      i++;
      const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');

      circle.setAttributeNS(null, 'cx', point[0]);
      circle.setAttributeNS(null, 'cy', point[1]);
      circle.setAttributeNS(null, 'r', 0.4);

      circle.setAttributeNS(null, 'fill', colour);

      if(!withoutId) {
        circle.setAttributeNS(null, 'class', 'point point-'+this.linePos+'-'+i);
      }

      this.logoEl.appendChild(circle);
    }
  }

  _generatePath(points, relative) {
    let type = null;

    if(points.length === 3) {
      type = 'Q';
    }
    else if(points.length === 4) {
      type = 'C';
    }
    else if(points.length % 2 === 0) {
      type = 'C';
    }
    else {
      throw 'Number of points must be 3 or an even number more than 3';
    }

    const pathPoints = ['M ', points[0][0], ',', points[0][1], type];

    for(let p = 1, l = points.length; p < l; p++) {
      if(p >= 4 && p % 2 === 0)
        pathPoints.push('S');

      pathPoints.push(points[p][0]);
      pathPoints.push(',');
      pathPoints.push(points[p][1]);
    }

    return pathPoints.join(' ');
  }

  _drawBezier(pathString, stroke) {
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttributeNS(null, 'd', pathString);
    path.setAttributeNS(null, 'stroke', stroke);
    path.setAttributeNS(null, 'fill', 'transparent');
    path.setAttributeNS(null, 'stroke-width', '1px');
    path.setAttributeNS(null, 'class', 'curve-bezier curve-bezier-'+this.linePos);
    this.logoEl.appendChild(path);
  }

  _gridLines() {
    for(let x = 0; x <= this.widthSvg; x += this.spacingW) {
      const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');

      line.setAttributeNS(null, 'x1', x);
      line.setAttributeNS(null, 'y1', 0);
      line.setAttributeNS(null, 'x2', x);
      line.setAttributeNS(null, 'y2', this.heightSvg);

      line.setAttributeNS(null, 'stroke', '#D0D0D0');

      this.logoEl.appendChild(line);
    }

    for(let y = 0; y <= this.heightSvg; y += this.spacingH) {
      const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');

      line.setAttributeNS(null, 'x1', 0);
      line.setAttributeNS(null, 'y1', y);
      line.setAttributeNS(null, 'x2', this.widthSvg);
      line.setAttributeNS(null, 'y2', y);

      line.setAttributeNS(null, 'stroke', '#D0D0D0');

      this.logoEl.appendChild(line);
    }
  }


  /* GENERAL FUNCTIONS */
  _mapNum(num, inMin, inMax, outMin, outMax) {
    return (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  _random(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
  }
};

export default LogoAnimation;
