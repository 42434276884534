const Utils = {};

export const debounce = Utils.debounce = function(func, wait, immediate) {
  let timeout;

  return function() {
    let context = this,
      args = arguments;

    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const debouncedRequestAnimationFrame = Utils.debouncedRequestAnimationFrame = function(timeoutId, callback) {
  if (timeoutId)
    window.cancelAnimationFrame(timeoutId);

  return timeoutId = window.requestAnimationFrame(callback);
};


export const throttle = Utils.throttle = function(callback, time) {
  let prev = 0;
  return (...args) => {
    let now = new Date().getTime();
    if(now - prev > time){
      prev = now;
      return callback(...args);
    }
  }
};

export const isMobileView = Utils.isMobileView = () => window.innerWidth <= 1023;


//https://youmightnotneedjquery.com/
export const docReady = Utils.docReady =  (fn) => {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

export const randomNum = Utils.randomNum =  (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
}

export default Utils;
