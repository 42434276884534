
import LogoAnimation from './logo-animation.js';
import Utils from './utils.js';

class LogoIti {
  constructor(el, isMobile, isDebug) {
    this.el = el;
    this.isMobile = isMobile || false;
    this.isDebug = isDebug || false;

    this.isReady = false;

    this.initLogo();

    let rafId = undefined;
    window.addEventListener('resize', event => {
      if(this.isReady)
        return;
      rafId = Utils.debouncedRequestAnimationFrame(rafId, this.initLogo.bind(this));
    });
  }

  initLogo() {
    if(Utils.isMobileView() && this.isMobile || !Utils.isMobileView() && !this.isMobile) {
      new LogoAnimation(-1, 'x', this.el.querySelector('.interactive-word'), this.isDebug, this.isMobile);
      new LogoAnimation(0, 'xy', this.el.querySelector('.technologies-word'), this.isDebug, this.isMobile);
      new LogoAnimation(1, 'y', this.el.querySelector('.institute-word'), this.isDebug, this.isMobile);

      this.isReady = true;
    }
  }
};

export default LogoIti;
